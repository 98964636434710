import { Stack } from "@mui/material";
import { ListType } from "../../articles";
import { Text2 } from "../../../../components/UI/Text";

export const List = ({ content }: { content: ListType }): JSX.Element => {
  return (
    <Stack>
      {content.title && (
        <Text2
          sx={{
            marginBottom: {
              xs: "0px !important",
              md: "12px !important",
            },
          }}
        >
          {content.title}
        </Text2>
      )}
      <ul style={{ marginTop: "6px" }}>
        {content.listItems.map((listItem) => (
          <li>
            <Text2 mb={1}>{listItem}</Text2>
          </li>
        ))}
      </ul>
    </Stack>
  );
};
