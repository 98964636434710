import img1 from "../../assets/logo/logo-on-white.png";
import img2 from "../../assets/latestUpdates/img2.jpg";
import img3 from "../../assets/latestUpdates/img3.jpg";
import img4 from "../../assets/latestUpdates/id4.png";
import { DateTime } from "luxon";

export interface ArticleType {
  id: number;
  date: DateTime;
  title: string;
  coverImage?: string;
  content: ContentType[];
  preview: string;
}

export type ArticleStyle = {
  marginBottom: number | string;
};

export type ContentType = ParagraphType | ListType | LinkType;

export type ParagraphType = {
  __typename: "Paragraph";
  paragraphs: string[];
  style?: ArticleStyle;
};

export type ListType = {
  __typename: "List";
  title?: string;
  listItems: string[];
};

export type LinkType = {
  __typename: "Link";
  label: string;
  href: string;
};

export const articles: ArticleType[] = [
  {
    id: 1,
    date: DateTime.fromISO("2022-11-09"),
    coverImage: img1,
    title: "We are officially started",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "Today we are finally starting the work on one of our plots we bought to build our first school on. We are removing the weeds to be able to lay a secure ground to build the school on.",
          "This is a small process but a big step towards our mission.",
        ],
      },
    ],
    preview:
      "Today we are finally starting the work on one of our plots we bought to build our first school on. We are removing the weeds to be able to lay a secure ground to build the school on.",
  },
  {
    id: 2,
    date: DateTime.fromISO("2022-11-22"),
    coverImage: img3,
    title: "One step closer!",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "We’ve finally removed the weeds! Now we are currently in the process of removing the roots and flattening the ground so we can achieve a solid ground to build upon.",
        ],
      },
    ],
    preview:
      "We’ve finally removed the weeds! Now we are currently in the process of removing the roots and flattening the ground so we can achieve a solid ground to build upon.",
  },
  {
    id: 3,
    date: DateTime.fromISO("2022-11-25"),
    coverImage: img2,
    title: "The ceremony",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "In Ghanaian culture, this ceremony is essential before starting the building process. Pouring of libation to our ancestors and gods to help us make everything successful.",
          "It’s just so beautiful!",
        ],
      },
    ],
    preview:
      "In Ghanaian culture, this ceremony is essential before starting the building process. Pouring of libation to our ancestors and gods to help us make everything successful.",
  },
  {
    id: 4,
    date: DateTime.fromISO("2022-12-10"),
    coverImage: img4,
    title: "First draft of the building plan is ready!",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "The first draft is finally done. Keep in mind that the plan is to take it step by step. We will start with one classroom at a time so that in the future we have a complete school from nursery up to primary 6.",
          "In the plan we have invluded a lunch hall, which is very uncommon for schools in Ghana. We want to make sure that the children have enough nutrition to be able to focus. Otherwise the children struggeling most with poverty wouldn't have the same chance as the rest. Which is what we want to change. ",
          "We have also included a soccer field and a playground i the middle. This way its easy for the teachers to look out for the children, while sitting in a shaded area.",
        ],
      },
    ],
    preview:
      "The first draft is finally done. Keep in mind that the plan is to take it step by step. We will start with one classroom at a time so that in the future we have a complete school from nursery up to primary 6.",
  },
  {
    id: 5,
    date: DateTime.fromISO("2023-09-30"),
    title: "Certified accountant wanted!",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "We're in the process of applying for a 90-account. To get our applicaiton approved is crucial to enable efficient and transparent fundraising. When applying for a 90-account it is required that we have a certified accountant in our board.",
          "If you're ready to make a meaningful difference and share your expertise for a good cause, or if you know someone who might be - don't hesitate to contact us!",
          "Our organization is completely non-profit. This means that we are looking for someone who is willing to contribute pro bono. Every little contribution counts as we strive to give the children of Ghana a better future.",
        ],
      },
      {
        __typename: "List",
        title: "Requirements",
        listItems: [
          "Authorized by the 'Swedish Auditors Inspectorate' as an accountant (auktoriserad revisor)",
          "Is resident in Sweden or in another state within the EEA",
          "Willing to get involved in board work (meetings, advice, etc.)",
        ],
      },
      {
        __typename: "Link",
        label: "Contact us",
        href: "https://classroomsforchange.org/contact",
      },
    ],
    preview:
      "We're in the process of applying for a 90-account. To get our applicaiton approved is crucial to enable efficient and transparent fundraising. When applying for a 90-account it is required that we have a certified accountant in our board.",
  },
  {
    id: 6,
    date: DateTime.fromISO("2023-12-11"),
    title: "Where does the donations go?",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "Last year marked the beginning of our mission to provide free education to those who need it most. Now, at a pivotal point, we're completing our first classroom!",
          "While last year's donations fell short, most of the money could be used to cost for legal paper work to securing our land. The rest went to building material, used today.",
          "Help us transform our vision into reality.",
          "To read specificaitons of what is needed to build a classroom, check out the link!",
        ],
      },
      {
        __typename: "Link",
        label: "Donate",
        href: "https://gofund.me/e8234334",
      },
    ],
    preview:
      "Last year marked the beginning of our mission to provide free education to those who need it most.",
  },
  {
    id: 7,
    date: DateTime.fromISO("2023-12-16"),
    title: "Breaking Ground: Taking the Next Steps",
    content: [
      {
        __typename: "Paragraph",
        paragraphs: [
          "I hope this update finds you well and filled with the same excitement and anticipation that I am experiencing.",
          "Last year, we were on the verge of breaking ground on the first classroom. However, we decided to pause the construction momentarily as we explored the possibility of acquiring the adjacent land plot. The idea was to secure a larger area, laying the foundation for future expansion.",
          "After careful consideration and reflection, I have come to the realization that sometimes the best way to plan for the future is to take bold steps in the present. While the prospect of a larger land plot held promise for the long term, the immediate need for a functional learning space for the children in the village cannot be overlooked.",
          "Therefore, I am thrilled to announce that we have decided to move forward with the construction of the first classroom using the materials we acquired last year. It is a momentous decision, and one that aligns with our commitment to making a tangible impact on the lives of these children as soon as possible.",
          "Stay tuned for updates as we embark on this exciting chapter in our mission to build a brighter future through education.",
          "With gratitude,",
        ],
      },
      {
        __typename: "Paragraph",
        style: { marginBottom: "0 !important" },
        paragraphs: ["Hadla Bergman,"],
      },
      {
        __typename: "Paragraph",
        paragraphs: ["Founder, Classrooms for Change"],
      },
    ],
    preview:
      "I hope this update finds you well and filled with the same excitement and anticipation that I am experiencing.",
  },
];
