import { Text1, Title3 } from "../../components/UI/Text";
import Grid from "@mui/material/Unstable_Grid2";
import { Mission_content } from "./text";
import { Card, Layout } from "../../components/UI/Common";
import { Container } from "@mui/material";

export const Mission = (): JSX.Element => {
  return (
    <Layout>
      <Container sx={{ paddingInline: "0 !important", marginBottom: 4 }}>
        <Card secondary>
          <Grid container spacing={2}>
            {Mission_content.map((m, i) => {
              const fullSize = i === 0;
              return (
                <Grid key={`${m.title}-${i}`} xs={12} sm={fullSize ? 12 : 6}>
                  <Title3 weight="bold">{m.title}</Title3>
                  {m.paragraphs.map((paragraph) => (
                    <Text1>{paragraph}</Text1>
                  ))}
                </Grid>
              );
            })}
          </Grid>
        </Card>
      </Container>
    </Layout>
  );
};
