import { Box, Stack } from "@mui/material";
import { Layout } from "../../components/UI/Common";
import { SpanTitle3, Text1, Title1, Title5 } from "../../components/UI/Text";
import { FAQ_Page_content } from "./text";

export const FAQ = (): JSX.Element => {
  const { questions } = FAQ_Page_content;
  return (
    <Layout>
      <Stack sx={{ gap: { xs: 2, md: 4, lg: 6 } }}>
        <Box>
          <Title1 mb={0} weight="bold">
            {FAQ_Page_content.title}
            <SpanTitle3 weight="bold">: {FAQ_Page_content.subtitle}</SpanTitle3>
          </Title1>
        </Box>
        {questions.map((q) => (
          <Stack>
            <Title5 weight="bold">{q.question}</Title5>
            {q.answers.map((answer) => (
              <Text1>{answer}</Text1>
            ))}
          </Stack>
        ))}
      </Stack>
    </Layout>
  );
};
