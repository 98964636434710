import { Stack } from "@mui/material";
import { ParagraphType } from "../../articles";
import { Text2 } from "../../../../components/UI/Text";

export const Paragraph = ({ content }: { content: ParagraphType }): JSX.Element => {
  return (
    <Stack>
      {content.paragraphs.map((paragraph, i) => (
        <Text2
          key={i}
          sx={{
            marginBottom: {
              xs: content.style !== undefined ? content.style.marginBottom : 1,
              md: content.style !== undefined ? content.style.marginBottom : 2,
              xl: content.style !== undefined ? content.style.marginBottom : 2,
            },
          }}
        >
          {paragraph}
        </Text2>
      ))}
    </Stack>
  );
};
