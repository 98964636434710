import { Section } from "../home/text";

export const Mission_content: Section[] = [
  {
    title: "What is our mission?",
    paragraphs: [
      "To bring quality education closer to both Krofu and the neighboring villages.",
      "We aim to alleviate the challenges that children face while growing up in rural villages. In many impoverished areas of Ghana, the few schools available are often under-resourced, with a shortage of qualified teachers and low-quality education. For many families, sending their children to schools further away becomes a necessity to provide them with a better educational opportunity. These children have to walk many miles to reach the nearest school, often on an empty stomach. Furthermore, many families simply cannot afford the costs of education, creating a significant financial burden.",
      "By removing these barriers, we can offer children a fair chance of a brighter future, providing them with a supportive environment where they can focus on learning. Our mission is to offer education entirely free of charge in the village of Krofu, reducing the distance children need to travel and ensuring they no longer have to walk so far. This will expand access to education, giving more children the opportunity to attend school and realize their full potential.",
    ],
  },
  {
    title: "How will we succeed with our mission?",
    paragraphs: [
      "Achieving meaningful and lasting change requires sustained commitment over many years. To ensure the success of our mission, we have a dedicated team based in Sweden, Denmark, and Ghana, working collaboratively to drive the project forward and maintain its momentum.",
      "We conduct ongoing field research in schools across Ghana, staying up-to-date with the latest educational practices. This approach allows us to remain adaptable, continuously incorporating proven methods to deliver high-quality education to the children who need it most.",
      "Our research also helps us identify potential challenges in both education and the management of charitable initiatives, enabling us to proactively address any obstacles that may arise.",
      "But to truly succeed, we need your support. Together, we can make a tangible difference in the lives of children in remote villages, providing them with opportunities for education they have long been denied. Join us in enhancing the educational opportunities in Krofu and beyond.",
    ],
  },
  {
    title: "What is our long-term vision?",
    paragraphs: [
      "Our long-term vision is to provide children with the tools they need to build a brighter future, helping them develop the skills and competencies necessary to break the cycle of poverty. Education is the foundation of this change, and our ultimate goal is to empower these children to not only transform their own lives but also contribute to the development of their communities.",
      "Our vision is built on the belief that education is a lifelong process, one that requires consistent investment. In addition to academic learning, we will focus on equipping children with practical life skills, fostering critical thinking, and building confidence. This comprehensive approach will ensure that our students are not only prepared for the future but are also active agents of change in their communities.",
      "To ensure long-term success, we are committed to continuous support, both in terms of resources and leadership. We will establish partnerships with local organizations, businesses, and international donors to ensure the school is well-funded and able to evolve in response to the changing needs of the community.",
    ],
  },
];
