import { Box } from "@mui/material";
import { Layout } from "../../components/UI/Common";
import Grid from "@mui/material/Unstable_Grid2";
import { Title4 } from "../../components/UI/Text";
import { useState } from "react";
import { articles } from "./articles";
import { ArticlePreview } from "./components/ArticlePreview/ArticlePreview";
import { CurrentArticle } from "./components/CurrentArticle/CurrentArticle";

export const LatestUpdates = (): JSX.Element => {
  const sortedArticles = articles.sort(function (a, b) {
    return b.id - a.id;
  });
  const [pageId, setPageId] = useState<number>(sortedArticles[0].id);
  const recentArticles = sortedArticles.slice(0, 4);
  const olderArticles = sortedArticles.slice(4);

  return (
    <Layout>
      <Box display="flex" flexDirection="column" gap={6}>
        <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={2}>
            <Grid xs={12} sm={8} lg={7}>
              <CurrentArticle
                page={pageId}
                article={sortedArticles[articles.length - pageId]}
                handleNextArticle={() => setPageId(pageId - 1)}
                handlePreviousArticle={() => setPageId(pageId + 1)}
              />
            </Grid>
            <Grid xs={12} sm={4} lg={5}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="space-between"
                height="100%"
              >
                {recentArticles.map((article) => (
                  <ArticlePreview
                    key={article.id}
                    page={pageId}
                    setPageId={setPageId}
                    article={article}
                  />
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box marginBottom={8}>
          <Title4 mb={2} weight="bold">
            Previous updates
          </Title4>
          <Grid container spacing={2}>
            {olderArticles.map((update) => (
              <Grid xs={12} sm={6} lg={4} key={update.id}>
                <ArticlePreview
                  key={update.id}
                  page={pageId}
                  setPageId={setPageId}
                  article={update}
                />
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Layout>
  );
};
