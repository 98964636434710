import { Box } from "@mui/material";
import { Text1, Title5 } from "../../../../components/UI/Text";
import Grid from "@mui/material/Unstable_Grid2";
import { BlobButton } from "../../../../components/UI/BlobButton";
import { Logo } from "../../../../components/Logo";
import { Slogan } from "../../../../components/Slogan/Slogan";
import { About_content } from "./text";
import { CTACard } from "../../../../components/CTACard/CTACard";
import { GO_FUND_ME_LINK } from "../../../../assets/variables/variables";

export const About = (): JSX.Element => {
  const about = About_content;
  return (
    <Box display="flex" flexDirection="row" justifyContent="flex-start" sx={{ flexGrow: 1 }}>
      <Grid container columnSpacing={{ xs: 4, sm: 6 }} rowSpacing={{ xs: 4, sm: 3 }}>
        <Grid xs={12} md={8}>
          <Box>
            <Logo onBackground="yellow" />
          </Box>
          {about.section1.map((section, i) => (
            <Box key={`section1-${Math.random()}-${i}`} mt={2}>
              {section.title && <Title5 weight="bold">{section.title}</Title5>}
              {section.paragraphs.map((paragraph) => (
                <Text1 mb={0} key={Math.random()}>
                  {paragraph}
                </Text1>
              ))}
            </Box>
          ))}
        </Grid>
        <CTACard content={about.CTACard} />
        <Grid xs={12}>
          {about.section2.map((section, i) => (
            <Box key={`section2-${Math.random()}-${i}`}>
              {section.title && <Title5 weight="bold">{section.title}</Title5>}
              {section.paragraphs.map((paragraph) => (
                <Text1 key={Math.random()}>{paragraph}</Text1>
              ))}
            </Box>
          ))}
        </Grid>
        <Grid sx={{ display: { xs: "none !important", md: "block !important" } }} sm={2.5}></Grid>
        <Grid xs={12} md={8}>
          <Slogan />
          <Text1
            sx={{
              marginBottom: {
                xs: "16px !important",
                md: "24px !important",
                xl: "32px !important",
              },
            }}
            mb={6}
          >
            {about.joinUs.text}
          </Text1>
          <Box>
            <a target="_blank" href={GO_FUND_ME_LINK} rel="noreferrer">
              <BlobButton text={about.joinUs.button} />
            </a>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
