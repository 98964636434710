import { LatestUpdates } from "./pages/latestUpdates/LatestUpdates";
import { Contact } from "./pages/contact/Contact";
import { Home } from "./pages/home/Home";
import { Donate } from "./pages/donate/Donate";
import { FAQ } from "./pages/faq/FAQ";
import { Mission } from "./pages/Mission/Mission";

type RouteType = {
  // Path for the route. Should match React Router specification.
  path: string;
  // Element for the route.
  node: JSX.Element;
  // Specified if the route should be shown in Saga navbar.
  navbar?: RouteTypeNavbar;
};

type RouteTypeNavbar = {
  // Text to show in navbar.
  text: string;
  // Url to link to in navbar.
  to: string;
};

export const useRoutes = (): RouteType[] => {
  return [
    {
      path: "/*",
      node: <Home />,
      navbar: {
        text: "Home",
        to: "/",
      },
    },
    {
      path: "/donate/*",
      node: <Donate />,
      navbar: {
        text: "Donate",
        to: "/donate",
      },
    },
    {
      path: "/mission/*",
      node: <Mission />,
      navbar: {
        text: "Mission & Vision",
        to: "/mission",
      },
    },
    {
      path: "/contact/*",
      node: <Contact />,
      navbar: {
        text: "Contact",
        to: "/contact",
      },
    },
    {
      path: "/faq/*",
      node: <FAQ />,
      navbar: {
        text: "FAQ",
        to: "/faq",
      },
    },

    {
      path: "/latest-updates/*",
      node: <LatestUpdates />,
      navbar: {
        text: "Updates",
        to: "/latest-updates",
      },
    },
  ];
};

function isDefined<T>(t: T | undefined): t is T {
  return t !== undefined;
}
export const useNavbarRoutes = (): RouteTypeNavbar[] => {
  return useRoutes()
    .map((r) => r.navbar)
    .filter(isDefined);
};
