import { Box } from "@mui/material";
import Grid from "@mui/material/Unstable_Grid2";
import { Card as CardUI } from "../../../../components/UI/Common";
import { Text2, Title4 } from "../../../../components/UI/Text";
import IconPeople from "@mui/icons-material/Diversity1";
import IconVillage from "@mui/icons-material/HolidayVillage";
import IconBehind from "@mui/icons-material/Handshake";
import styled from "styled-components";
import { BlobButton } from "../../../../components/UI/BlobButton";

export const GetToKnow = (): JSX.Element => {
  const iconSize = "60px";
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid mb={10} container spacing={{ xs: 2, md: 3 }} columns={{ xs: 12 }} alignItems="flex-end">
        <Grid xs={12} sm={6} lg={4} display="flex">
          <CardLayout
            icon={<IconPeople sx={{ fontSize: iconSize }} />}
            title="The people of Krofu"
            text="Get to know some of the locals living in Krofu!"
          />
        </Grid>
        <Grid xs={12} sm={6} lg={4} display="flex">
          <CardLayout
            icon={<IconBehind sx={{ fontSize: iconSize, marginTop: -2 }} />}
            title="Who’s behind our school?"
            text="Get to know some of the people who help us make our mission come true!"
          />
        </Grid>
        <Grid xs={12} sm={12} lg={4} display="flex">
          <CardLayout
            icon={<IconVillage sx={{ fontSize: iconSize }} />}
            title="The villag"
            text="Get to know the village! Krofu is a small village in central Ghana."
          />
        </Grid>
      </Grid>
    </Box>
  );
};

type CardLayoutProps = {
  icon: JSX.Element;
  title: string;
  text: string;
};
export const CardLayout = ({ icon, title, text }: CardLayoutProps): JSX.Element => {
  return (
    <Card>
      <Box display="flex" flexDirection="column" justifyContent="center" textAlign="center">
        <IconBox display="flex" justifyContent="center" mt={3} mb={6}>
          {icon}
        </IconBox>
        <Title4 sx={{ lineHeight: 1 }} mb={1} weight="bold">
          {title}
        </Title4>
        <Text2 sx={{ lineHeight: 1.25, marginTop: 1 }} mb={3}>
          {text}
        </Text2>
        <Box display="flex" justifyContent="center">
          <BlobButton text="Coming coon..." fontSize={2} px={8} variant="secondary" />
        </Box>
      </Box>
    </Card>
  );
};
const Card = styled(CardUI)`
  width: 310px;
  margin-left: auto;
  margin-right: auto;
  cursor: pointer;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  height: 400px;
  transform: scale(1);
  transition: transform 0.6s ease-in-out;
  &:hover {
    transform: scale(1.025);
    }
  }
  
`;
const IconBox = styled(Box)`
  width: 100%;
  text-align: center;
`;
