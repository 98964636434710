import { Text1, Title4 } from "../../components/UI/Text";
import Grid from "@mui/material/Unstable_Grid2";
import { Link } from "react-router-dom";
import { Box } from "@mui/material";
import { BlobButton } from "../UI/BlobButton";
import { Card } from "../UI/Common";
import { CTA_Card_Internal } from "../../text";

type CTACardProps = {
  content: CTA_Card_Internal;
};

export const CTACard = ({ content }: CTACardProps): JSX.Element => {
  return (
    <Grid xs={12} md={4} mb={{ sm: 1, md: 0 }}>
      <Box display="flex" alignItems="flex-end" height="100%">
        <Card secondary>
          <Title4
            sx={{
              marginBottom: {
                xs: "8px !important",
                md: "16px !important",
              },
            }}
            weight="bold"
          >
            {content.title}
          </Title4>
          <Text1
            sx={{
              marginBottom: {
                xs: "16px !important",
                md: "24px !important",
              },
            }}
          >
            {content.text}
          </Text1>
          <Link to={content.to}>
            <BlobButton variant="secondary" text={content.button} />
          </Link>
        </Card>
      </Box>
    </Grid>
  );
};
