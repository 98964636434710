import { GO_FUND_ME_LINK, GO_FUND_ME_TARGET } from "../../assets/variables/variables";
import { Page } from "../../text";
import { DonateCard_Card } from "./components/DonateCard/DonateCard";
import { Quote_Quote } from "./components/Quote/Quote";

interface Donate_Page extends Page {
  subtitle: string;
  card: DonateCard_Card;
  quotes: Quote_Quote[];
}

export const Donate_Page_content: Donate_Page = {
  title: "You really want to see change?",
  subtitle:
    "Do something incredible <span class='bold'>now</span>,</br > the power is in <span class='bold'>your hands</span>.",
  card: {
    title: "Donate",
    text: "Your donation means a lot for us",
    button: {
      label: "Click to donate!",
      href: GO_FUND_ME_LINK,
    },
    annotation: {
      key: "Goal",
      value: GO_FUND_ME_TARGET,
    },
  },
  quotes: [
    {
      quote: "No one is useless in this world who lightens the burdens of another.",
      author: "Charles Dickens",
    },
    {
      quote: "Happiness doesn’t result from what we get, but from what we give.",
      author: "Ben Carson",
    },
    {
      quote: "The meaning of life is to find your gift. The purpose of life is to give it away.",
      author: "Pablo Picasso",
    },
  ],
};
