import { Size } from "../../../../config/theme.config";
import styled from "styled-components";
import { Box } from "@mui/material";
import { Text4, Title4 } from "../../../../components/UI/Text";
import { ArticleType } from "../../articles";
import { ReactNode } from "react";

type ArticleLayoutProps = {
  article: ArticleType;
  children: ReactNode;
};

export const ArticleLayout = ({ article, children }: ArticleLayoutProps): JSX.Element => {
  return (
    <Box
      sx={{
        marginBottom: {
          xs: "12px !important",
          md: "0px !important",
        },
      }}
    >
      {article.coverImage && (
        <ImageContainer>
          <img alt="cover" src={article.coverImage} />
        </ImageContainer>
      )}
      <Text4 mb={0.5}>{article.date.toRelative()}</Text4>
      <Title4
        lineHeight={1}
        weight="bold"
        sx={{
          marginBottom: {
            xs: "8px !important",
            md: "16px !important",
          },
        }}
      >
        {article.title}
      </Title4>
      {children}
    </Box>
  );
};

const ImageContainer = styled.div`
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-radius: ${(props) => props.theme.borderRadius.small};
  margin-bottom: ${(props) => props.theme.spacer}px;
  display: flex;
  align-items: center;
  img {
    width: 100%;
    height: auto;
    image-position: center;
  }
  @media only screen and (max-width: ${Size.xl}px) {
    height: auto;
  }
`;
