import { Employee, HADLA_BERGMAN, JESPER_LUNDING } from "../../assets/variables/variables";
import { Page } from "../../text";

interface Contact_Page extends Page {
  form: {
    name: string;
    email: string;
    message: string;
    submitButton: string;
  };
  contacts: Employee[];
}

export const Contact_Page_content: Contact_Page = {
  title: "We’ve been waiting for you.",
  form: {
    name: "Name",
    email: "Email",
    message: "Message",
    submitButton: "Send message",
  },
  contacts: [HADLA_BERGMAN, JESPER_LUNDING],
};
