import { Box } from "@mui/material";
import { Text1, Text2 } from "../../../../components/UI/Text";
import QuoteIcon from "@mui/icons-material/FormatQuote";

export type Quote_Quote = {
  quote: string;
  author: string;
};

export const Quote = ({ quote }: { quote: Quote_Quote }): JSX.Element => {
  return (
    <Box mb={2} display="flex" flexDirection="row" flexWrap="nowrap">
      <QuoteIcon fontSize="large" sx={{ lineHeight: 1, marginTop: -1 }} />
      <Text1 mb={0}>
        {quote.quote}
        <Text2 mt={1} weight="boldSlim">
          - {quote.author}
        </Text2>
      </Text1>
    </Box>
  );
};
