type Material_Content = {
  title: string;
  note: string;
  materials: string[];
};

export const Material_content: Material_Content = {
  title: "Examples of what it takes to build one classroom",
  note: "These are just a few examples of things",
  materials: [
    "800 building blocks.",
    "9 iron rods.",
    "80 bags of cement.",
    "Water and polytanks.",
    "20 tons of river sand.",
    "20 tons of stones.",
    "4-5 windows and 1 door.",
    "Wooden boards.",
    "Electrical work.",
    "Ventilation system.",
    "Clay.",
    "Construction labor.",
    "A roof with water drains.",
    "Paint for walls and handmade furniture.",
    "Hiring a techer and sponsering their salary.",
    "Buying everything from books and pencils to shalkboard.",
    "Construction workers for building the classroom.",
    "Contractor to build chairs and tables.",
  ],
};
