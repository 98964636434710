import { Stack } from "@mui/material";
import { Layout } from "../../components/UI/Common";
import { GetToKnow } from "./components/GetToKnow/GetToKnow";
import { About } from "./components/About/About";
import { Material } from "./components/Material/Material";

export const Home = (): JSX.Element => {
  return (
    <Layout>
      <Stack sx={{ gap: { xs: 6, md: 8, lg: 10 } }}>
        <About />
        <Material />
        <GetToKnow />
      </Stack>
    </Layout>
  );
};
