import { Box } from "@mui/material";
import { BlobButton } from "../../../../components/UI/BlobButton";
import { Text1, Text4 } from "../../../../components/UI/Text";
import { Dispatch, SetStateAction } from "react";
import { ArticleType } from "../../articles";
import styled from "styled-components";
import { Card } from "../../../../components/UI/Common";
import { Size } from "../../../../config/theme.config";

type ArticlePreviewProps = {
  article: ArticleType;
  page: number;
  setPageId: Dispatch<SetStateAction<number>>;
};

export const ArticlePreview = ({ article, page, setPageId }: ArticlePreviewProps): JSX.Element => {
  return (
    <MiniatureCard secondary padding={0} style={{ opacity: page === article.id ? 0.7 : 1 }}>
      <Box flexGrow="1" padding={2}>
        <Text4 mb={0}>{article.date.toRelative()}</Text4>
        <Ellipsis numOfLines={1}>
          <Text1 mb={0} weight="bold">
            {article.title}
          </Text1>
        </Ellipsis>
        <Ellipsis numOfLines={3}>
          <Text4
            sx={{
              marginBottom: {
                xs: "4px !important",
                md: "8px !important",
              },
            }}
          >
            {article.preview}
          </Text4>
        </Ellipsis>
        {page === article.id ? (
          <Text4
            sx={{
              paddingBlock: "5.5px",
            }}
            mb={0}
            weight="bold"
          >
            Currently reading
          </Text4>
        ) : (
          <BlobButton
            onClick={() => setPageId(article.id)}
            disabled={page === article.id}
            fontSize={1.5}
            py={0.5}
            px={1.5}
            text="Read more"
          />
        )}
      </Box>
    </MiniatureCard>
  );
};

const MiniatureCard = styled(Card)`
  padding: 0;
  display: flex;
  flex-direction: row;
  border-radius: ${(props) => props.theme.borderRadius.small};
  @media only screen and (max-width: ${Size.sm}px) {
    margin-bottom: ${(props) => props.theme.spacer * 2}px;
  }
`;
const Ellipsis = styled.span<{ numOfLines: number }>`
  p {
    display: -webkit-box;
    -webkit-line-clamp: ${(props) => props.numOfLines};
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
`;
