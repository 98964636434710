import { Box } from "@mui/material";
import { Fragment } from "react";
import { articles, ArticleType } from "../../articles";
import styled from "styled-components";
import { Card, LinkButton } from "../../../../components/UI/Common";
import { Size } from "../../../../config/theme.config";
import { ArticleLayout } from "../Layout/Layout";
import { Paragraph } from "../Paragraph/Paragraph";
import { List } from "../List/List";
import { Link } from "../Link/Link";

type ArticleProp = {
  article: ArticleType;
  page: number;
  handleNextArticle: () => void;
  handlePreviousArticle: () => void;
};

export const CurrentArticle = ({
  article,
  page,
  handleNextArticle,
  handlePreviousArticle,
}: ArticleProp): JSX.Element => {
  return (
    <MagnifiedCard secondary padding={2}>
      <ArticleLayout article={article}>
        {article.content.map((content, i) => {
          switch (content.__typename) {
            case "Paragraph":
              return <Paragraph key={`${article.id}-${i}`} content={content} />;
            case "List":
              return <List key={`${article.id}-${i}`} content={content} />;
            case "Link":
              return <Link key={`${article.id}-${i}`} content={content} />;
            default:
              return <Fragment key={`${article.id}-${i}`} />;
          }
        })}
      </ArticleLayout>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <LinkButton disabled={page === articles.length} onClick={handlePreviousArticle}>
          Previous
        </LinkButton>
        <LinkButton disabled={page === 1} onClick={handleNextArticle}>
          Next
        </LinkButton>
      </Box>
    </MagnifiedCard>
  );
};

const MagnifiedCard = styled(Card)`
  min-height: 720px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  @media only screen and (max-width: ${Size.sm}px) {
    height: auto;
    min-height: 475px;
  }
`;
