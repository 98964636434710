import { Link as LinkUI, Stack } from "@mui/material";
import { LinkType } from "../../articles";

export const Link = ({ content }: { content: LinkType }): JSX.Element => {
  return (
    <Stack>
      <LinkUI target="_blank" href={content.href}>
        {content.label}
      </LinkUI>
    </Stack>
  );
};
