import { Box, Container } from "@mui/material";
import styled from "styled-components";
import { Text3 } from "../UI/Text";
import { HADLA_BERGMAN, JESPER_LUNDING } from "../../assets/variables/variables";
import { Size } from "../../config/theme.config";

export const Footer = (): JSX.Element => {
  return (
    <FooterContariner>
      <Container maxWidth="xl">
        <SpaceBetween>
          <Box>
            <Text3 fontWeight="bold !important" mb={0}>
              Classrooms for Change
            </Text3>
            <Text3 mb={0}>Stockholm, Sweden, Europe</Text3>
            <Text3 mb={0}>Krofu, Ghana, Africa</Text3>
          </Box>
          <Box>
            <Text3 mb={0}>{HADLA_BERGMAN.email}</Text3>
            <Text3 mb={0}>{JESPER_LUNDING.email}</Text3>
          </Box>
        </SpaceBetween>
      </Container>
    </FooterContariner>
  );
};

const FooterContariner = styled.footer`
  background-color: ${(props) => props.theme.colors.black} !important;
  color: ${(props) => props.theme.colors.grey08} !important;
  padding-top: ${(props) => props.theme.spacer * 8}px;
  padding-bottom: ${(props) => props.theme.spacer * 20}px;
  position: relative;
  z-index: 10;
`;

const SpaceBetween = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: ${(props) => props.theme.spacer * 3}px;
  @media only screen and (min-width: ${Size.md}px) {
    flex-direction: row;
    justify-content: space-between;
  }
`;
