import { Page } from "../../text";

interface FAQ_Page extends Page {
  questions: Question[];
}

type Question = {
  question: string;
  answers: string[];
};

export const FAQ_Page_content: FAQ_Page = {
  title: "FAQ",
  subtitle: "Frequently asked questions",
  questions: [
    {
      question: "What Does the Fundraising Goal Cover?",
      answers: [
        "The amount we hope to raise in Q4 2024 will cover the costs of completing the first classroom. The remaining funds will be allocated to building two additional classrooms. We will need further funding to complete and operate the school.",
        "We have divided the funding into different parts. The first amount we applied for in Q1 2024 was to build the framework/foundation of one classroom. The 180,000 SEK will cover the minimum amount required to establish a 90-bankgiro account. This amount includes 5,000 SEK (+25% VAT) for the annual fee to the Swedish Fundraising Control, and 35,000 SEK to meet the remaining requirements set by the Swedish Fundraising Control, such as an authorized auditor.",
        "Establishing a 90-bankgiro account is essential for our organization, as Swedish banks prohibit non-profit associations connected to high-risk countries like Ghana from opening standard bank accounts without one. Beyond the administrative expenses required by the Swedish Fundraising Control, all donations are dedicated to the construction and operation of the school. While the funds raised in Q4’s fundraiser will not fully complete the school, they will provide the necessary foundation to set up the 90-bankgiro-linked account. Once this account is established, we will launch a new fundraising campaign, seamlessly integrating donations from our current GoFundMe fundraiser (after deducting annual and auditor fees) to further support our mission.",
      ],
    },
    {
      question: "What is our current focus?",
      answers: [
        "To complete the first classroom, costs remain for the facade, roofing, labor, other building components, and materials necessary for constructing school desks and other essential facilities.",
        "To successfully launch the school, we aim to establish three classrooms, employ three qualified teachers, and collaborate with educational organizations to develop a curriculum that meets Ghana’s standards while being tailored to optimize the children’s learning and development. Additionally, we will provide educational materials for at least 30 students and ensure the operation and maintenance of a meal program that delivers nutritious lunches to the children, among other critical requirements.",
      ],
    },
  ],
};
