import { CTA_Card_Internal } from "../../../../text";
import { Section } from "../../text";

interface About_Content {
  section1: Section[];
  section2: Section[];
  joinUs: JoinUs;
  CTACard: CTA_Card_Internal;
}

type JoinUs = {
  text: string;
  button: string;
};

export const About_content: About_Content = {
  section1: [
    {
      paragraphs: [
        "Classrooms for Change Ghana is dedicated to transforming education in underserved villages such as Krofu. Our organization is committed to delivering high-quality education by maintaining a low student-to-teacher ratio and investing in the future of each student.",
      ],
    },
    {
      title: "What is out plan?",
      paragraphs: [
        "We provide comprehensive support from start to finish. For instance, if we operate with three classrooms, we will not introduce new classes each year. Instead, we guide these initial classes through their entire Primary and Lower Secondary education. Upon graduation, we fully sponsor their attendance at quality high schools and assist them in securing sponsorships for university education. This approach ensures that students receive consistent and uninterrupted support throughout their educational journey.",
      ],
    },
  ],
  section2: [
    {
      title: "Why is this needed?",
      paragraphs: [
        "In our experience working in Ghana, we’ve observed that sustained, comprehensive support is rare. Many educational charities provide only temporary aid, which limits the impact on students’ long-term educational outcomes. Additionally, we have noted that many classrooms have 35 or more students per teacher, with the teacher's role often limited to delivering information rather than ensuring that each student truly understands and learns the material. This lack of personalized attention can significantly hinder students' academic progress. We believe that long-term, consistent support, combined with a low student-to-teacher ratio, is essential for making a significant and lasting impact.",
        "At Classrooms for Change Ghana, we are committed to ensuring that every donation is used effectively. We prioritize complete transparency in our financial dealings to ensure that each contribution directly supports our mission of providing lasting educational improvements.",
        "Thank you for supporting our mission to create a brighter future for students in Ghana.",
      ],
    },
  ],
  CTACard: {
    title: "Get to know our mission",
    text: "Want to read about our long-term vision?",
    button: "Yes please!",
    to: "/mission",
  },
  joinUs: {
    text: "Join our mission to bring education closer to the village children.",
    button: "Donate now",
  },
};
