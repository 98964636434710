import { Box, Link, TextField } from "@mui/material";
import { Stack } from "@mui/system";
import { BlobButton } from "../../components/UI/BlobButton";
import { Card, Col, Layout } from "../../components/UI/Common";
import { Text1, Title1 } from "../../components/UI/Text";
import Grid from "@mui/material/Unstable_Grid2";
import IconPhone from "@mui/icons-material/PhoneIphone";
import IconInstagram from "@mui/icons-material/Instagram";
import IconEmail from "@mui/icons-material/Markunread";
import emailjs from "emailjs-com";
import Swal from "sweetalert2";
import styled from "styled-components";
import { Size } from "../../config/theme.config";
import { Contact_Page_content } from "./text";
import { EMAILJS_SERVICEID, EMAILJS_TEMPLATEID, EMAILJS_UESERID } from "./variables";
import { INSTAGRAM_LINK } from "../../assets/variables/variables";

export const Contact = (): JSX.Element => {
  const handleOnSubmit = (e: any) => {
    e.preventDefault();
    emailjs.sendForm(EMAILJS_SERVICEID, EMAILJS_TEMPLATEID, e.target, EMAILJS_UESERID).then(
      (result) => {
        console.log(result.text);
        Swal.fire({
          icon: "success",
          title: "Message Sent Successfully",
        });
      },
      (error) => {
        console.log(error.text);
        Swal.fire({
          icon: "error",
          title: "Ooops, something went wrong",
          text: error.text,
        });
      }
    );
    e.target.reset();
  };
  return (
    <Layout>
      <Box sx={{ flexGrow: 1 }}>
        <Title1 sx={{ display: { xs: "block", md: "none" } }} weight="bold">
          {Contact_Page_content.title}
        </Title1>
        <GridParent container spacing={2}>
          <Grid xs={12} md={6}>
            <Stack gap={2}>
              <Title1 sx={{ display: { xs: "none", md: "block" } }} weight="bold">
                {Contact_Page_content.title}
              </Title1>
              <Link href={INSTAGRAM_LINK} target="_blank">
                <Box display="flex" gap={0.5} flexDirection="row" alignItems="center">
                  <IconInstagram />
                  <Text1 mb={0}>@classroomsforchange</Text1>
                </Box>
              </Link>
              {Contact_Page_content.contacts.map((contact) => (
                <Stack>
                  <Text1 mb={0} fontWeight="500 !important">
                    {contact.fullName}
                  </Text1>
                  <Link href={`mailto:${contact.email}`}>
                    <Box display="flex" gap={0.5} flexDirection="row" alignItems="center">
                      <IconEmail />
                      <Text1 mb={0}>{contact.email}</Text1>
                    </Box>
                  </Link>
                  <Link href={contact.phone.href}>
                    <Box display="flex" gap={0.5} flexDirection="row" alignItems="center">
                      <IconPhone />
                      <Text1 mb={0}>{contact.phone.label}</Text1>
                    </Box>
                  </Link>
                </Stack>
              ))}
            </Stack>
          </Grid>
          <Grid xs={12} md={6} mb={2}>
            <Card>
              <Col py={3} px={3}>
                <form id="contact-form" action="POST" onSubmit={handleOnSubmit}>
                  <Stack gap={4}>
                    <TextField
                      name="from_name"
                      required
                      id="outlined-basic"
                      label={Contact_Page_content.form.name}
                      variant="outlined"
                    />
                    <TextField
                      name="from_email"
                      required
                      id="outlined-basic"
                      label={Contact_Page_content.form.email}
                      variant="outlined"
                    />
                    <TextField
                      name="message"
                      required
                      id="outlined-basic"
                      label={Contact_Page_content.form.message}
                      variant="outlined"
                    />
                    <BlobButton variant="secondary" text={Contact_Page_content.form.submitButton} />
                  </Stack>
                </form>
              </Col>
            </Card>
          </Grid>
        </GridParent>
      </Box>
    </Layout>
  );
};

const GridParent = styled(Grid)`
  @media only screen and (max-width: ${Size.md}px) {
    flex-direction: column-reverse !important;
  }
`;
