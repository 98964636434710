export const GO_FUND_ME_LINK: string = "https://gofund.me/9cd9ebb5";
export const GO_FUND_ME_TARGET: string = "180, 000 SEK";
export const INSTAGRAM_LINK: string = "https://www.instagram.com/classroomsforchange/";

export type Employee = {
  fullName: string;
  email: string;
  phone: { label: string; href: string };
};

export const JESPER_LUNDING: Employee = {
  fullName: "Jesper Lunding",
  email: "jesper.lunding@classroomsforchange.org",
  phone: { label: "+46 70-416 95 01", href: "tel:(+46)(0704169501)" },
};

export const HADLA_BERGMAN: Employee = {
  fullName: "Hadla Bergman",
  email: "hadla.bergman@classroomsforchange.org",
  phone: { label: "+46 76-115 58 42", href: "tel:(+46)(0761155842)" },
};
