import { Title1 } from "../UI/Text";
import styled from "styled-components";

export const Slogan = (): JSX.Element => {
  return (
    <Title1>
      <Bold>Help</Bold> us <Bold>help</Bold> you <Bold>help</Bold>
      <F3>the children</F3>
    </Title1>
  );
};

const Bold = styled.span`
  font-weight: 700;
`;

const F3 = styled.span`
  font-family: "Permanent Marker", cursive;
`;
