import { Box } from "@mui/material";
import { Text1, Text3, Title3 } from "../../../../components/UI/Text";
import Grid from "@mui/material/Unstable_Grid2";
import { Material_content } from "./text";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";

export const Material = (): JSX.Element => {
  return (
    <Box
      style={{ background: "#ffffff", borderRadius: 12, width: "100%" }}
      sx={{
        padding: {
          xs: "18px !important",
          md: "32px !important",
        },
        maxWidth: {
          xs: "500px",
          md: "100%",
        },
        margin: {
          xs: "0 auto",
          md: "initial",
        },
      }}
    >
      <Box
        sx={{
          marginBottom: {
            xs: "8px !important",
            md: "24px !important",
          },
        }}
      >
        <Title3 mb={0} weight="bold">
          {Material_content.title}
        </Title3>
        <Text3 fontStyle="italic">{Material_content.note}</Text3>
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <Grid container spacing={{ xs: 1, md: 2 }} columns={{ xs: 12 }}>
          <Grid xs={12} md={6} lg={5} sx={{ paddingBottom: { md: "inherit", xs: 0 } }}>
            {[...Array(10)].map((_, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                marginBottom={0.75}
              >
                <AddCircleOutlineIcon
                  fontSize="small"
                  sx={{ marginTop: "3px", color: "#F0D608" }}
                />
                <Text1 mb={0}>{Material_content.materials[index]}</Text1>
              </Box>
            ))}
          </Grid>
          <Grid xs={12} md={6} lg={6} sx={{ paddingTop: { md: "inherit", xs: 0 } }}>
            {[...Array(8)].map((_, index) => (
              <Box
                key={index}
                display="flex"
                flexDirection="row"
                alignItems="flex-start"
                gap={1}
                marginBottom={0.75}
              >
                <AddCircleOutlineIcon
                  fontSize="small"
                  sx={{ marginTop: "3px", color: "#F0D608" }}
                />
                <Text1 mb={0}>{Material_content.materials[index + 11]}</Text1>
              </Box>
            ))}
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
