import { Box } from "@mui/system";
import styled from "styled-components";
import { Layout } from "../../components/UI/Common";
import { Title1, Title2 } from "../../components/UI/Text";
import { Size } from "../../config/theme.config";
import Grid from "@mui/material/Unstable_Grid2";
import { Donate_Page_content } from "./text";
import { Quote } from "./components/Quote/Quote";
import { DonateCard } from "./components/DonateCard/DonateCard";

export const Donate = (): JSX.Element => {
  return (
    <Layout direction="column">
      <Box mb={{ xs: 2, sm: 4, md: 6 }} display="flex" flexDirection="column">
        <Title1 mb={1} weight="bold">
          {Donate_Page_content.title}
        </Title1>
        <Title2
          lineHeight={1.2}
          dangerouslySetInnerHTML={{
            __html: Donate_Page_content.subtitle,
          }}
        />
      </Box>
      <Box sx={{ flexGrow: 1 }}>
        <GridParent container spacing={{ xs: 6, md: 10 }}>
          <Grid sx={{}} xs={12} lg={7}>
            {Donate_Page_content.quotes.map((quote) => (
              <StyledQuoteWrapper key={quote.author}>
                <Quote quote={quote} />
              </StyledQuoteWrapper>
            ))}
          </Grid>
          <Grid xs={12} lg={5} sx={{ paddingBlock: { xs: 2, sm: 4 } }}>
            <DonateCard card={Donate_Page_content.card} />
          </Grid>
        </GridParent>
      </Box>
    </Layout>
  );
};

const StyledQuoteWrapper = styled.div`
  &:nth-child(even) {
    margin-left: auto;
    width: 70%;
  }
`;

const GridParent = styled(Grid)`
  @media only screen and (max-width: ${Size.lg}px) {
    flex-direction: column-reverse !important;
  }
`;
