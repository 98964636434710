import { Card } from "../../../../components/UI/Common";
import { Box } from "@mui/system";
import { Text1, Title4 } from "../../../../components/UI/Text";
import { Annotation, ExternalButton } from "../../../../text";
import { BlobButton } from "../../../../components/UI/BlobButton";

export type DonateCard_Card = {
  title: string;
  text: string;
  button: ExternalButton;
  annotation: Annotation;
};

export const DonateCard = ({ card }: { card: DonateCard_Card }): JSX.Element => {
  return (
    <Card reverse>
      <Box display="flex" flexDirection="row" justifyContent="space-between">
        <Title4 weight="bold" mb={1}>
          {card.title}
        </Title4>
        <Title4>
          <span style={{ fontSize: 14 }}>{card.annotation.key}</span> {card.annotation.value}
        </Title4>
      </Box>
      <Text1>{card.text}</Text1>
      <a target="_blank" href={card.button.href} rel="noreferrer">
        <BlobButton variant="reverse" text={card.button.label} />
      </a>
    </Card>
  );
};
